import React from 'react'
import { FormattedMessage } from 'react-intl'
import logo from './AtlasPLogo@2x.png'
import styles from './index.module.css'

const AboutUs = () => (
  <div className={styles.wrapper}>
    <div className={styles.top}>
      <p>
        <FormattedMessage id="aboutUs.slogan" />
      </p>
      <a href="http://atlaspio.mikecrm.com/0uJEmbE" target="blank">
        <button>
          <FormattedMessage id="contactUs" />
        </button>
      </a>
    </div>
    <div className={styles.bottom}>
      <img src={logo} alt="" />
      <p>
        <FormattedMessage id="aboutUs.desc" />
      </p>
    </div>
  </div>
)

export default AboutUs
