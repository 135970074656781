import React from 'react'
import AboutUs from '../components/AboutUs'
import Layout from '../components/Layout'

const AboutPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale} location={location}>
    <AboutUs />
  </Layout>
)

export default AboutPage
